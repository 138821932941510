import { FormProvider, useForm } from 'react-hook-form'
import { Nullable } from 'shared/hooks/createUseMergedFirebase'
import { Options } from 'shared/types/fleet'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import RoomExitIcon from '../../icons/room-exit.svg?react'
import { RoomExitEditor } from './RoomExitEditor'

type FormOptions = Nullable<Options>

export type DeviceOptionsDialogProps = {
  deferred: Deferred<Nullable<Options>>
  options: Nullable<Options>
}

export const DeviceOptionsDialog = ({
  deferred,
  options,
}: DeviceOptionsDialogProps) => {
  const context = useForm<FormOptions>({
    defaultValues: options,
    mode: 'onBlur',
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = context

  const handleClose = () => {
    if (!isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog
      title={<Title>Éditer les options du boitier</Title>}
      onClose={handleClose}
    >
      <FormProvider {...context}>
        <form
          onSubmit={handleSubmit(async (options) => {
            return deferred.resolve(options)
          })}
        >
          <div className="flex min-h-40 min-w-96 flex-col justify-between gap-3">
            <div className="rounded bg-white bg-opacity-10 p-4">
              <div className="flex cursor-pointer items-center justify-between">
                <div className="flex gap-2">
                  <RoomExitIcon className="w-8 fill-current" />
                  <Title>roomExitTimeRange</Title>
                </div>
              </div>
              <div className="mt-3">
                <RoomExitEditor />
              </div>
            </div>

            <div className="flex justify-end  gap-3">
              <Button variant="outline" onClick={handleClose}>
                Annuler
              </Button>
              <SubmitButton isSubmitting={isSubmitting}>Valider</SubmitButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  )
}
