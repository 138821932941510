import { DeviceMonitoringComment } from 'common/types'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Serial } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Textarea } from '../../components/ui/textarea'
import { set } from '../../firebaseMethods'

export const BatchEditOfflinesDialog = ({
  deferred,
  serials,
}: {
  deferred: Deferred<void>
  serials: Set<Serial>
}) => {
  const { source } = useContext(DataContext)

  const { handleSubmit, register, formState } =
    useForm<DeviceMonitoringComment>()

  const handleClose = () => {
    deferred.reject(DIALOG_CLOSED_REASON)
  }

  return (
    <Dialog
      title={<Title>Édition de {serials.size} appareils</Title>}
      onClose={handleClose}
    >
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async (data) => {
          await Promise.all(
            Array.from(serials).map((serial) => {
              const timestamp = DateTime.now().toMillis()

              // choice of set() instead of push() to use timestamp as firebase Key
              // (Statistically almost impossible case of duplicated firebase Key: only one person doing the monitoring each day)
              return set(`devicesMonitoringComments/${serial}/${timestamp}`, {
                comment: data.comment,
                source: source,
              })
            }),
          )

          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-2">
          <label>Commentaire surveillance des Hors-Lignes</label>
          <Textarea
            {...register('comment', {
              setValueAs: (v) => v.trim(),
            })}
          />
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={formState.isSubmitting}
          >
            Annuler
          </Button>
          <SubmitButton
            isSubmitting={formState.isSubmitting}
            variant={formState.isDirty ? 'default' : 'destructive'}
          >
            {formState.isDirty ? 'Valider' : 'Supprimer'}
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
