import { TimeZone } from '../utils/timeZones'
import { Category } from './alert'
import { TimeRange } from './timeRange'
import { FacilityId, FirebaseKey } from './utils'

export type FacilityType = 'customer' | 'experimenter' | 'workshop' | 'inactive'

// RFC 5646 language tag
export const Languages = ['en', 'fr'] as const

export type Language = (typeof Languages)[number]

export interface TimeRanges {
  recordingTimeRange: TimeRange
  analysisTimeRange: TimeRange
  monitoringTimeRange: TimeRange
}

export interface Wifi {
  ssid: string
  password: string
  isHidden: boolean
  priority: number
}

export type Wifis = Record<FirebaseKey, Wifi>

export interface VpnClientConfig {
  lastUpdated: number
  config: string
}

export type Zones = Record<FirebaseKey, Zone>

export interface Zone {
  name: string
  order: number
  regions: Regions
  isHoliday: boolean
  holidayZoneId?: FirebaseKey
}

export type Regions = Record<FirebaseKey, Region>

export interface Region {
  roomRange?: RoomRange
  timeRange?: TimeRange
  category?: Category
}

export interface RoomRange {
  from: string
  to: string // inclusive
}

export type Facility = {
  type: FacilityType
  displayName: string
  address: string
  city: string
  name: string
  zipCode: string
  comment: string
  areSoundsDeleted: boolean
  timeZone: TimeZone
  language: Language
  contacts?: string[]
  wifis?: Wifis
  zones?: Zones
} & TimeRanges

export type Facilities = Record<FacilityId, Facility>

export type DeviceStatus =
  | 'spare'
  | 'active'
  | 'pending'
  | 'disabled'
  | 'outOfOrder'

export type OptionTypes = 'roomExitTimeRange'

export type Options = {
  roomExitTimeRange?: TimeRange
}

export type PartType = 'SDCARD' | 'POWER' | 'CABLE' | 'MICROPHONE' | 'CASE'

// Note that for 'CASE' PartType, the PartId is the id of the device, as written on CASE
// The 'COMPUTER' ok (and comments) fields are actually in the device (status)
export type PartsIds = Record<PartType, FirebaseKey>

export type Device = {
  room: string
  status: DeviceStatus
  comment: string
  orderId: FirebaseKey // orderId for COMPUTER
  facilityId: FacilityId
  partsIds: PartsIds
  wifis?: Wifis
  ssid?: string
  ipAddress?: string
  vpnIpAddress?: string
  macAddress?: string
  accessPointMacAddress?: string
  vpnClientConfig?: VpnClientConfig
  secretId?: string
} & Partial<TimeRanges> &
  Options
