import {
  getFacilityTypeIcon,
  isFacilityTypeCustomerOrExperimenter,
} from 'common/facilityType'
import { DeviceEssential, FacilityDevices, Timestamp } from 'common/types'
import {
  AlertTriangle,
  Check,
  ChevronRight,
  ChevronsDown,
  Copy,
  ExternalLink,
  Loader,
  Square,
  SquareCheckBig,
} from 'lucide-react'
import { DateTime } from 'luxon'
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { NavLink } from 'react-router-dom'
import { Serial } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { isDeviceRecording } from 'shared/utils/device'
import { formatMsDate } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { DeviceMonitoringItem } from '../../components/DeviceListItem'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { OfflineGraph, Timeline } from '../../components/OfflineGraph'
import { Select } from '../../components/Select'
import { Hint, Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { cn } from '../../components/ui/cn'
import { useFirebase } from '../../hooks/useFirebase'
import { usePing } from '../../hooks/usePing'
import { useToast } from '../../hooks/useToast'
import Grafana from '../../icons/grafana.svg?react'
import {
  OfflineWarning,
  offlineWarningColor,
  offlineWarningText,
} from '../../utils/offlines'
import {
  getSortedDeviceSerials,
  getSortedFacilityIds,
} from '../../utils/sorters'
import { BatchEditOfflinesDialog } from './BatchEditOfflinesDialog'

const initialStartDate = DateTime.now()
  .minus({ days: 1 })
  .set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
  .toMillis()

export const Offlines: React.FC = () => {
  const { toast } = useToast()
  const { pingStatuses, handlePing } = usePing()

  const { facilities, facilityDevices, grafana } = useContext(DataContext)

  const sortedFacilityIds = useMemo(() => {
    return getSortedFacilityIds(facilities).filter((facilityId) => {
      const { type } = facilities[facilityId]
      return isFacilityTypeCustomerOrExperimenter(type)
    })
  }, [facilities])

  const facilityTypeIcons = useMemo(() => {
    const icons: Record<string, string> = {}
    sortedFacilityIds.forEach((facilityId) => {
      const { type } = facilities[facilityId]
      icons[facilityId] = getFacilityTypeIcon(type)
    })
    return icons
  }, [facilities, sortedFacilityIds])

  const [batchEditDialogData, setBatchEditDialogData] = useState<{
    deferred: Deferred<void>
    serials: Set<Serial>
  } | null>(null)
  const [openedFacilities, setOpenedFacilities] = useState<Set<string>>(
    new Set(),
  )
  const [openedDevices, setOpenedDevices] = useState<Set<Serial>>(new Set())
  const [startDate, setStartDate] = useState(initialStartDate)
  const [selectedSerials, setSelectedSerials] = useState<Set<Serial>>(new Set())

  // #region fetching datas

  const { data: devicesOffline, loading: loadingDevicesOffline } =
    useFirebase(`devicesOffline`)

  const {
    data: devicesMonitoringComments,
    loading: loadingDevicesMonitoringComments,
  } = useFirebase(`devicesMonitoringComments`)

  const { data: devicesOfflineHistory, loading: loadingDevicesOfflineHistory } =
    useFirebase(`devicesOfflineHistory`)

  const { data: devicesVersion, loading: loadingDevicesVersion } =
    useFirebase(`devicesVersion`)

  // #endregion fetching datas

  const handleBatchEditOfflines = async () => {
    const deferred = new Deferred<void>()

    setBatchEditDialogData({
      deferred,
      serials: selectedSerials,
    })

    try {
      await deferred.promise
      setSelectedSerials(new Set())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setBatchEditDialogData(null)
    }
  }

  const handleCopyToClipboard = (serial: Serial) => {
    navigator.clipboard.writeText(serial)
    toast({
      variant: 'default',
      title: `Serial copié`,
      description: serial,
    })
  }

  const handleDateChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const daysToSubtract = parseInt(event.target.value, 10)
      const newDate = DateTime.now()
        .minus({ days: daysToSubtract })
        .set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
        .toMillis()
      setStartDate(newDate)
    },
    [],
  )

  const toggleItem = <T,>(
    setStateFn: Dispatch<SetStateAction<Set<T>>>,
    item: T,
  ) => {
    setStateFn((prevSet) => {
      const newSet = new Set(prevSet)
      if (newSet.has(item)) {
        newSet.delete(item)
      } else {
        newSet.add(item)
      }
      return newSet
    })
  }

  const toggleFacilityDisplay = (facilityId: string) => {
    toggleItem(setOpenedFacilities, facilityId)
  }

  const toggleDeviceDisplay = (serial: Serial) => {
    toggleItem(setOpenedDevices, serial)
  }

  const toggleDeviceSelection = (serial: Serial) => {
    toggleItem(setSelectedSerials, serial)
  }

  const devicesTimeline = useMemo(() => {
    const output: Record<Serial, Timeline> = {}
    const now = DateTime.now().toMillis()
    const monitoringTsRange = now - startDate

    Object.entries(devicesOfflineHistory ?? {}).forEach(([serial, history]) => {
      const sortedEntries = Object.entries(history)
        .filter(([timestamp, _isOffline]) => {
          return parseInt(timestamp) > startDate
        })
        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))

      if (sortedEntries.length === 0) return

      output[serial] = {}
      sortedEntries.forEach(([currentTs, isOffline], index) => {
        const isLastEntry = index === sortedEntries.length - 1
        const currentTime = parseInt(currentTs)
        const nextTime = isLastEntry
          ? now
          : parseInt(sortedEntries[index + 1][0])

        output[serial][currentTs] = {
          isOffline,
          relativeDuration: Math.round(
            ((nextTime - currentTime) / monitoringTsRange) * 100,
          ),
        }
      })
    })

    return output
  }, [startDate, devicesOfflineHistory])

  const filteredFacilityDevices = useMemo(() => {
    if (!devicesOffline) return {}

    return Object.entries(facilityDevices).reduce<FacilityDevices>(
      (acc, [facilityId, devices]) => {
        const sortedDeviceSerials = getSortedDeviceSerials(devices)

        const recordingDevices = sortedDeviceSerials.reduce<
          Record<Serial, DeviceEssential>
        >((devicesAcc, serial) => {
          if (!isDeviceRecording(devices[serial].status)) {
            if (devicesOffline[serial] !== true) {
              // all devices not recording and onlines
              devicesAcc[serial] = devices[serial]
            }
          } else {
            if (devicesOffline[serial] === true) {
              // all devices recording and offlines
              devicesAcc[serial] = devices[serial]
            } else if (devicesTimeline[serial]) {
              // all devices recording with history
              devicesAcc[serial] = devices[serial]
            }
          }

          return devicesAcc
        }, {})

        if (!isObjectEmpty(recordingDevices)) {
          acc[facilityId] = recordingDevices
        }

        return acc
      },
      {},
    )
  }, [devicesOffline, devicesTimeline, facilityDevices])

  const devicesOfflineWarnings = useMemo(() => {
    const offlineWarnings: Record<Serial, OfflineWarning> = {}

    Object.values(facilityDevices).forEach((devices) => {
      Object.keys(devices).forEach((serial) => {
        offlineWarnings[serial] =
          devicesOffline?.[serial] !== true
            ? 'online'
            : devicesOfflineHistory?.[serial]
              ? 'offline_less_than_a_week'
              : 'offline_more_than_a_week'
      })
    })

    return offlineWarnings
  }, [devicesOffline, devicesOfflineHistory, facilityDevices])

  const isNewOffline = useCallback(
    (serial: Serial): boolean => {
      return (
        devicesOffline?.[serial] === true &&
        Object.keys(devicesTimeline[serial] ?? {}).length % 2 !== 0
      )
    },
    [devicesOffline, devicesTimeline],
  )

  const isStillOnlineWithHistory = useCallback(
    (serial: Serial): boolean => {
      return (
        devicesOffline?.[serial] !== true &&
        Object.keys(devicesTimeline[serial] ?? {}).length % 2 === 0
      )
    },
    [devicesOffline, devicesTimeline],
  )

  type FacilitiesOfflinesEvolution = Record<
    string,
    {
      nbNewOfflines: number
      nbStillOnlineWithHistory: number
      nbRecordingOfflines: number
    }
  >

  const facilitiesHistoryEvolution = useMemo(() => {
    if (!devicesOffline) return {}

    return Object.entries(
      filteredFacilityDevices,
    ).reduce<FacilitiesOfflinesEvolution>((acc, [facilityId, devices]) => {
      let nbRecordingOfflines: number = 0
      let nbNewOfflines: number = 0
      let nbStillOnlineWithHistory: number = 0

      Object.keys(devices).forEach((serial) => {
        if (devicesOffline[serial] === true) nbRecordingOfflines++

        if (isStillOnlineWithHistory(serial)) nbStillOnlineWithHistory++

        if (isNewOffline(serial)) nbNewOfflines++
      })

      acc[facilityId] = {
        nbNewOfflines,
        nbStillOnlineWithHistory,
        nbRecordingOfflines,
      }

      return acc
    }, {})
  }, [
    devicesOffline,
    filteredFacilityDevices,
    isNewOffline,
    isStillOnlineWithHistory,
  ])

  if (
    loadingDevicesVersion ||
    loadingDevicesOffline ||
    loadingDevicesMonitoringComments ||
    loadingDevicesOfflineHistory
  )
    return <div>Chargement des données ...</div>

  const formatComment = (
    timestamp: Timestamp,
    comment: string,
    source: string,
  ) => (
    <pre key={timestamp} className="text-wrap">
      <span className="opacity-45">{formatMsDate(parseInt(timestamp))}</span>
      <span> {comment} </span>
      <span className="opacity-45">({source})</span>
    </pre>
  )

  return (
    <>
      {batchEditDialogData && (
        <BatchEditOfflinesDialog {...batchEditDialogData} />
      )}

      <div className="flex flex-col gap-3 overflow-y-auto p-3">
        <div className="flex items-center gap-2">
          <ChevronsDown
            onClick={() => {
              openedFacilities.size
                ? setOpenedFacilities(new Set())
                : setOpenedFacilities(new Set(Object.keys(facilities)))
            }}
            className={`transform cursor-pointer duration-300 ${
              openedFacilities.size ? 'rotate-0' : '-rotate-90'
            }`}
          />
          <label className="flex flex-row items-baseline gap-2">
            <Title>Début de surveillance des boitiers hors-lignes: </Title>
            <Select onChange={handleDateChange} defaultValue={1}>
              {[...Array(8)].map((_, index) => (
                <option key={index} value={index}>
                  J-{index}
                </option>
              ))}
            </Select>
            <Hint>
              {`(
              ${DateTime.fromMillis(startDate).toLocaleString(
                DateTime.DATE_FULL,
              )} à 6h00 du matin)`}
            </Hint>
          </label>
        </div>
        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          {sortedFacilityIds.map((facilityId) => {
            const { displayName, comment } = facilities[facilityId]

            const devices = filteredFacilityDevices[facilityId]
            // if (!devices) return null

            const opened = openedFacilities.has(facilityId)

            const nbDevices = Object.keys(
              facilityDevices[facilityId] ?? {},
            ).length
            const nbRecordingOfflines =
              facilitiesHistoryEvolution[facilityId]?.nbRecordingOfflines ?? 0
            const nbNewOfflines =
              facilitiesHistoryEvolution[facilityId]?.nbNewOfflines ?? 0
            const nbStillOnlineWithHistory =
              facilitiesHistoryEvolution[facilityId]
                ?.nbStillOnlineWithHistory ?? 0

            return (
              <div
                className="flex flex-col gap-2 px-2 last:mb-96"
                key={facilityId}
              >
                <div
                  onClick={() => toggleFacilityDisplay(facilityId)}
                  className="flex w-full items-center gap-2 hover:bg-cyan-800 hover:bg-opacity-40"
                >
                  <div className="flex items-center gap-2" title={comment}>
                    <ChevronRight
                      className={cn(
                        `transform cursor-pointer duration-300 ${opened ? 'rotate-90' : 'rotate-0'}`,
                        !devices && 'opacity-25',
                      )}
                    />
                    <NavLink
                      to={`/explorer/${facilityId}`}
                      target="_blank"
                      type="button"
                      className="opacity-50"
                    >
                      <ExternalLink strokeWidth="1" />
                    </NavLink>
                    <div className="whitespace-nowrap">
                      {facilityTypeIcons[facilityId]} {displayName}
                    </div>
                  </div>
                  <div className="flex items-baseline gap-2">
                    <div className="opacity-50">
                      {nbRecordingOfflines} / {nbDevices} appareils
                    </div>
                    {nbNewOfflines ? (
                      <div
                        className="flex w-10 items-center justify-center rounded-md bg-orange-500"
                        title="Nouveaux Hors-ligne"
                      >
                        {-nbNewOfflines}
                      </div>
                    ) : null}
                    {nbStillOnlineWithHistory ? (
                      <div
                        className="flex w-10 items-center justify-center rounded-md bg-gray-700"
                        title="Boitiers ayant été Hors-ligne"
                      >
                        {nbStillOnlineWithHistory}
                      </div>
                    ) : null}
                  </div>
                </div>
                {opened && (
                  <div className="ml-10 flex flex-col gap-1">
                    {comment.replace(/\s/g, '').length ? (
                      <Hint className="m-2 justify-start rounded-none border-l-4 p-4">
                        <pre className="text-wrap">{comment}</pre>
                      </Hint>
                    ) : null}
                    <div className="mb-5 flex flex-col">
                      {Object.entries(devices ?? {})
                        .sort(([serialA, _deviceA], [serialB, _deviceB]) => {
                          // First level: Sort by offline status
                          const offlineComparison =
                            (devicesOffline?.[serialB] ? 1 : 0) -
                            (devicesOffline?.[serialA] ? 1 : 0)

                          if (offlineComparison !== 0) {
                            return offlineComparison
                          }

                          // Second level: Sort by timeline existence
                          const timelineA = devicesTimeline[serialA]
                          const timelineB = devicesTimeline[serialB]

                          const hasTimelineA =
                            Object.keys(timelineA ?? {}).length > 0
                          const hasTimelineB =
                            Object.keys(timelineB ?? {}).length > 0

                          if (hasTimelineA !== hasTimelineB) {
                            return hasTimelineA ? 1 : -1
                          }

                          // If both levels are equal, maintain original order
                          return 0
                        })
                        .map(([serial, device]) => {
                          const sortedComments = Object.entries(
                            devicesMonitoringComments?.[serial] || {},
                          ).sort(([timestampA], [timestampB]) => {
                            return parseInt(timestampB) - parseInt(timestampA)
                          })

                          const formattedComments = sortedComments.map(
                            ([timestamp, { comment, source }]) =>
                              formatComment(timestamp, comment, source),
                          )

                          const commentsTooltip = sortedComments
                            .map(
                              ([timestamp, { comment, source }]) =>
                                `${formatMsDate(parseInt(timestamp))} ${comment} (${source})`,
                            )
                            .join('\n')

                          return (
                            <div
                              className={cn(
                                'flex items-center gap-2 hover:bg-cyan-800 hover:bg-opacity-40',
                                selectedSerials.has(serial) && 'bg-cyan-800',
                              )}
                              key={serial}
                            >
                              <div
                                className="cursor-pointer"
                                onClick={() => toggleDeviceSelection(serial)}
                              >
                                {selectedSerials.has(serial) ? (
                                  <SquareCheckBig />
                                ) : (
                                  <Square />
                                )}
                              </div>
                              <div
                                title={
                                  offlineWarningText[
                                    devicesOfflineWarnings[serial]
                                  ]
                                }
                                className={cn(
                                  `m-0.5 h-5 w-5 shrink-0 rounded-full`,
                                  offlineWarningColor[
                                    devicesOfflineWarnings[serial]
                                  ],
                                )}
                              ></div>
                              <div className="w-5 shrink-0 text-end text-sm italic text-gray-500">
                                {devicesTimeline[serial]
                                  ? Object.keys(devicesTimeline[serial]).length
                                  : '-'}
                              </div>
                              <div className="flex h-5 w-48 shrink-0 items-center">
                                <OfflineGraph
                                  deviceTimeline={devicesTimeline[serial]}
                                  deviceIsOffline={
                                    devicesOffline?.[serial] === true
                                  }
                                />
                              </div>
                              <div className="flex items-center gap-2">
                                <div
                                  className={cn(
                                    `m-0.5 h-7 w-10 rounded px-2 py-0.5 text-center`,
                                    devicesVersion?.[serial] !==
                                      Object.keys(devicesVersion ?? {}).at(
                                        -1,
                                      ) && 'bg-red-900',
                                  )}
                                >
                                  v{devicesVersion?.[serial] ?? '1'}
                                </div>
                                <Button
                                  className={`bg-primary text-primary-foreground m-0.5 flex h-7 w-20 items-center justify-center rounded`}
                                  onClick={() => handlePing(serial)}
                                  disabled={pingStatuses[serial] === 'progress'}
                                >
                                  Ping
                                  {pingStatuses[serial] === 'progress' ? (
                                    <Loader className="p-0.5" />
                                  ) : pingStatuses[serial] === 'error' ? (
                                    <AlertTriangle className="text-destructive p-0.5" />
                                  ) : pingStatuses[serial] === 'success' ? (
                                    <Check className="text-destructive-foreground p-0.5" />
                                  ) : null}
                                </Button>
                                <Button
                                  variant={'link'}
                                  size={'xs'}
                                  onClick={() => handleCopyToClipboard(serial)}
                                  title={'Copier le serial : ' + serial}
                                >
                                  <Copy strokeWidth={1} />
                                </Button>
                                <NavLink
                                  to={`/explorer/${facilityId}/${serial}`}
                                  target="_blank"
                                  className="h-6"
                                >
                                  <Button
                                    variant={'link'}
                                    size={'xs'}
                                    title="Détail boitier Fleet"
                                  >
                                    <ExternalLink strokeWidth="1" />
                                  </Button>
                                </NavLink>
                                <a
                                  href={`${grafana.url}/d/ari-v2/ari-v2-vm?var-facility=${displayName}&var-room=${device.room}&var-ari_id=${serial}`}
                                  target="_blank"
                                  className="opacity-70"
                                  title="Détail boitier Grafana"
                                >
                                  <Grafana className="h-6 w-5 fill-current stroke-current" />
                                </a>
                              </div>
                              <DeviceMonitoringItem
                                facilityId={facilityId}
                                device={device}
                                serial={serial}
                              />
                              {isNewOffline(serial) ? (
                                <div
                                  className="h-5 w-10 shrink-0 rounded-md bg-orange-500"
                                  title="Nouveau Hors-ligne"
                                ></div>
                              ) : null}
                              {isStillOnlineWithHistory(serial) ? (
                                <div
                                  className="h-5 w-10 shrink-0 rounded-md bg-gray-700"
                                  title="Boitier ayant été Hors-ligne"
                                ></div>
                              ) : null}
                              <div
                                className={cn(
                                  'my-2 ml-2 w-full cursor-pointer border-l-4 border-white pl-4',
                                  openedDevices.has(serial)
                                    ? 'border-opacity-45 py-2'
                                    : 'line-clamp-1 border-opacity-0',
                                )}
                                title={commentsTooltip}
                                onClick={() => toggleDeviceDisplay(serial)}
                              >
                                {formattedComments}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div
          className={cn(
            'flex flex-row gap-2',
            !selectedSerials.size && 'hidden',
          )}
        >
          <Button
            title="Supprimer sélection des appareils"
            onClick={() => setSelectedSerials(new Set())}
          >
            Supprimer sélection
          </Button>
          <Button
            title="Ajouter un commentaire de surveillance sur ces appareils"
            className="flex-1"
            onClick={handleBatchEditOfflines}
          >
            {selectedSerials.size
              ? `Commenter ${selectedSerials.size} appareils`
              : 'Sélectionner au moins 1 appareil'}
          </Button>
        </div>
      </div>
    </>
  )
}
