import { NULLED } from 'common/log'
import { DeviceHistory as History } from 'common/types'
import { Pencil } from 'lucide-react'
import { DateTime } from 'luxon'
import React, { useContext, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import {
  DeviceStatus,
  PartsIds,
  VpnClientConfig,
  Wifi,
  Wifis,
} from 'shared/types/fleet'
import { TimeRange } from 'shared/types/timeRange'
import { FacilityId, FirebaseKey, Room } from 'shared/types/utils'
import { getDeviceStatusDisplay, isDeviceRecording } from 'shared/utils/device'
import { roomDisplayName } from 'shared/utils/room'
import { timeRangeString } from 'shared/utils/timeRange'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { Hint, Title } from '../../components/Text'
import { DataContext } from '../../DataProvider'
import { EditCommentDialog } from '../../dialogs/EditCommentDialog'
import { set } from '../../firebaseMethods'
import { DevicePieces } from './DevicePieces'

type PropertyValue =
  | typeof NULLED
  | FacilityId
  | PartsIds
  | Room
  | DeviceStatus
  | TimeRange
  | VpnClientConfig
  | Wifis

type PropertyFormat =
  | 'facilityId'
  | 'partsIds'
  | 'room'
  | 'status'
  | 'timeRange'
  | 'vpnClientConfig'
  | 'wifis'

export type HistoryProperties = {
  [key: string]: {
    text: string
    format: PropertyFormat
  }
}

export const PROPERTIES: HistoryProperties = {
  facilityId: { text: 'Nouvel établissement', format: 'facilityId' },
  partsIds: { text: 'Remplacement de pièce', format: 'partsIds' },
  room: { text: 'Nouvelle chambre', format: 'room' },
  status: { text: 'Nouveau statut', format: 'status' },
  vpnClientConfig: { text: 'Nouveau VPN', format: 'vpnClientConfig' },
  wifis: { text: 'Nouveau WiFi', format: 'wifis' },
  wifiConfigs: { text: 'Nouveau WiFi', format: 'wifis' }, // Legacy: former wifis
  analysisTimeRange: {
    text: "Nouvel horaire d'enregistrement",
    format: 'timeRange',
  },
  monitoringTimeRange: {
    text: 'Nouvel horaire de surveillance',
    format: 'timeRange',
  },
  recordingTimeRange: {
    text: "Nouvel horaire d'analyse",
    format: 'timeRange',
  },
  roomExitTimeRange: {
    text: 'Nouvelle sortie de chambre',
    format: 'timeRange',
  },
}

export const DeviceHistory = ({ history }: { history: History }) => {
  const { serial } = useParams() as { serial: string }

  const { facilities } = useContext(DataContext)

  const [editCommentDialogData, setEditCommentDialogData] = useState<{
    deferred: Deferred<void>
    comment: string
    action: (comment: string) => Promise<void> // KILLME
  } | null>(null)

  const handleEditComment = async (key: string, comment = '') => {
    const deferred = new Deferred<void>()

    setEditCommentDialogData({
      deferred,
      comment,
      action: (newComment: string) =>
        set(`history/devices/${serial}/${key}/data/comment`, newComment),
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditCommentDialogData(null)
    }
  }

  const displayProperty = (
    format: PropertyFormat,
    value: PropertyValue,
  ): React.ReactNode => {
    if (value === NULLED) return 'supprimé'

    switch (format) {
      case 'facilityId':
        return (
          <NavLink to={`/explorer/${value}`}>
            {facilities[value as FacilityId].displayName}
          </NavLink>
        )
      case 'partsIds':
        return (
          <div className="border-primary border-l pl-2">
            <DevicePieces pieceIds={value as PartsIds} />
          </div>
        )
      case 'wifis':
        return Object.entries(value as Wifis).map(
          ([key, wifi]: [FirebaseKey, Partial<Wifi> | typeof NULLED]) => {
            return (
              <div key={key}>
                <div>
                  {key}
                  {wifi === NULLED ? ' supprimé' : null}
                </div>
                {wifi !== NULLED ? (
                  <div className="border-primary ml-4 flex flex-col border-l pl-2">
                    {wifi.ssid ? <div>SSID: {wifi.ssid}</div> : null}
                    {wifi.priority ? (
                      <div>Priorité: {wifi.priority}</div>
                    ) : null}
                    {wifi.password ? <div>Mot de passe modifié</div> : null}
                    {wifi.isHidden ? (
                      <div>Caché: {wifi.isHidden ? 'oui' : 'non'}</div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            )
          },
        )
      case 'room':
        return roomDisplayName(value as string)
      case 'status':
        return (
          <div
            className={
              isDeviceRecording(value as DeviceStatus)
                ? 'text-green-400'
                : 'text-red-400'
            }
          >
            {getDeviceStatusDisplay(value as DeviceStatus)}
          </div>
        )
      case 'timeRange':
        return timeRangeString(value as TimeRange)
      default:
        return JSON.stringify(value, null, 2)
    }
  }

  return (
    <div className="flex flex-1 flex-col gap-3">
      {editCommentDialogData && (
        <EditCommentDialog {...editCommentDialogData} />
      )}

      <Title>Historique</Title>

      <div className="flex flex-1 flex-col gap-6">
        {Object.entries(history)
          .sort(
            ([, { timestamp: timestampA }], [, { timestamp: timestampB }]) =>
              timestampB - timestampA,
          )
          .map(([key, { timestamp, data }]) => {
            return (
              <div key={key} className="flex flex-col gap-2">
                <div className="flex flex-row flex-wrap gap-3 align-baseline">
                  <div className="shrink-0">
                    Le{' '}
                    {DateTime.fromMillis(timestamp)
                      .setLocale('fr')
                      .toLocaleString(DateTime.DATETIME_SHORT)}
                  </div>
                  <div
                    className="ml-4 flex cursor-pointer gap-2 text-gray-500 "
                    onClick={() => handleEditComment(key, data.comment)}
                  >
                    {data.comment && <pre>{data.comment}</pre>}
                    <Pencil size={18} />
                  </div>
                </div>
                <div className="ml-4 flex flex-col gap-2">
                  {Object.entries(data).map(([key, value], index) => {
                    if (key === 'comment' || key === 'orderId') return

                    const propertyName = PROPERTIES[key]?.text
                    const propertyFormat = PROPERTIES[key]?.format

                    if (propertyName && propertyFormat) {
                      return (
                        <div key={index} className="flex items-baseline gap-2">
                          <div>{propertyName}: </div>
                          <div>{displayProperty(propertyFormat, value)}</div>
                        </div>
                      )
                    }

                    return (
                      <div key={index} className="flex items-baseline gap-2">
                        <span className="font-bold text-red-600">
                          propriété inconnue: {key}
                        </span>
                        <Hint>(contacter le développeur)</Hint>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
