import { Deferred } from 'shared/utils/web/deferred'
import { Dialog, DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { Text, Title } from '../../components/Text'
import { Button } from '../../components/ui/button'

export const ContactsDialog = ({
  deferred,
  contacts,
}: {
  deferred: Deferred<void>
  contacts: string[]
}) => {
  return (
    <Dialog title={null} onClose={() => deferred.reject(DIALOG_CLOSED_REASON)}>
      <div className="flex flex-col gap-3">
        {contacts.map((contact, index) => {
          return (
            <div className="flex flex-col gap-2" key={index}>
              <Title>Contact #{index + 1}</Title>
              <Text className="whitespace-break-spaces">{contact}</Text>
            </div>
          )
        })}
      </div>

      <div className="flex items-center justify-end gap-3">
        <Button
          variant="outline"
          onClick={() => deferred.reject(DIALOG_CLOSED_REASON)}
        >
          Fermer
        </Button>
      </div>
    </Dialog>
  )
}
