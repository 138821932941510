import { FACILITY_TYPES, getFacilityTypeDisplay } from 'common/facilityType'
import { Trash2 as Trash } from 'lucide-react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Facility, Languages } from 'shared/types/fleet'
import { TimeRange } from 'shared/types/timeRange'
import { isValidFirebaseKey } from 'shared/utils/firebase'
import { TimeZones } from 'shared/utils/timeZones'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Select } from '../../components/Select'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import { Wrapped, unwrap, wrap } from '../../utils/fieldArray'

const DEFAULT_TIME_RANGE: TimeRange = { start: '20:00', end: '08:00' }

type EditedFacility = Omit<Facility, 'contacts' | 'wifis'> & {
  contacts: Wrapped<string>[]
}

const defaultFacility: EditedFacility = {
  type: 'experimenter',
  displayName: '',
  name: '',
  address: '',
  zipCode: '',
  city: '',
  comment: '',
  contacts: [],
  recordingTimeRange: DEFAULT_TIME_RANGE,
  analysisTimeRange: DEFAULT_TIME_RANGE,
  monitoringTimeRange: DEFAULT_TIME_RANGE,
  areSoundsDeleted: false,
  timeZone: 'Europe/Paris',
  language: 'fr',
}

function asEditedFacility(facility: Facility | undefined) {
  if (facility === undefined) return undefined

  const editedFacility: EditedFacility = {
    ...facility,
    contacts: wrap(facility.contacts ?? []),
  }

  return editedFacility
}

export const EditFacilityDialog = ({
  facility,
  deferred,
  action,
}: {
  facility?: Facility
  deferred: Deferred<void>
  action: (facility: Facility) => Promise<void>
}) => {
  const { control, handleSubmit, register, setValue, formState } =
    useForm<EditedFacility>({
      defaultValues: asEditedFacility(facility) ?? defaultFacility,
    })

  const {
    fields: contactFields,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({
    control,
    name: 'contacts',
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog
      title={
        <Title>
          {facility ? "Éditer l'établissement" : 'Ajouter un établissement'}
        </Title>
      }
      onClose={handleClose}
    >
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async ({ contacts, ...values }) => {
          await action({
            ...values,
            contacts: unwrap(contacts ?? []),
          })
          deferred.resolve()
        })}
      >
        <div className="flex gap-3">
          <div className="flex flex-col gap-1">
            <label>Type</label>
            <Select
              {...register('type')}
              onChange={(e) => {
                if (e.target.value === 'customer') {
                  setValue('areSoundsDeleted', true)
                }
              }}
            >
              {FACILITY_TYPES.map((type) => (
                <option key={type} value={type}>
                  {getFacilityTypeDisplay(type)}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex flex-1 flex-col gap-1">
            <label>ID</label>
            <Input
              disabled={facility !== undefined}
              {...register('displayName', {
                required: 'ID requis',
                validate: {
                  displayNameIsTrimmed: (displayName: string) =>
                    displayName.trim() === displayName ||
                    "Espace en trop dans le nom de l'établissement",
                  displayNameIsValid: (displayName: string) =>
                    isValidFirebaseKey(displayName) ||
                    "Caractère interdit dans le nom de l'établissement",
                },
              })}
            />
            {formState.errors.displayName && (
              <Error>{formState.errors.displayName.message}</Error>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-3 md:flex-row">
          <div className="flex flex-col gap-1">
            <label>Nom</label>
            <Input
              {...register('name', {
                required: 'Nom requis',
              })}
            />
            {formState.errors.name && (
              <Error>{formState.errors.name.message}</Error>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label>Adresse</label>
            <Input
              {...register('address', {
                required: 'Adresse requise',
              })}
            />
            {formState.errors.address && (
              <Error>{formState.errors.address.message}</Error>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3 md:flex-row">
          <div className="flex flex-col gap-1">
            <label>Code postal</label>
            <Input
              {...register('zipCode', {
                required: 'Code postal requis',
              })}
            />
            {formState.errors.zipCode && (
              <Error>{formState.errors.zipCode.message}</Error>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label>Ville</label>
            <Input
              {...register('city', {
                required: 'Ville requise',
              })}
            />
            {formState.errors.city && (
              <Error>{formState.errors.city.message}</Error>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <label>Fuseau horaire</label>
          <Select {...register('timeZone')}>
            {TimeZones.map((timeZone) => (
              <option key={timeZone} value={timeZone}>
                {timeZone}
              </option>
            ))}
          </Select>
        </div>

        <div className="flex flex-col gap-1">
          <label>Langue</label>
          <Select {...register('language')}>
            {Languages.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </Select>
        </div>

        <div className="flex items-center justify-start gap-4">
          <div>Suppression des sons</div>
          <Input
            type="checkbox"
            className="w-5"
            {...register('areSoundsDeleted')}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label>Commentaire</label>
          <Textarea {...register('comment')} />
        </div>

        <div className="flex flex-col gap-3">
          {contactFields.map((field, index) => (
            <div className="flex flex-col gap-1" key={field.id}>
              <label>Contact #{index + 1}</label>

              <div className="flex gap-3">
                <Textarea
                  {...register(`contacts.${index}.value` as const, {
                    required: 'Contact requis',
                  })}
                />
                <Button onClick={() => removeContact(index)}>
                  <Trash />
                </Button>
              </div>
              {formState.errors.contacts &&
                formState.errors.contacts[index]?.value && (
                  <Error>
                    {formState.errors.contacts[index]?.value?.message}
                  </Error>
                )}
            </div>
          ))}
          <Button onClick={() => appendContact({ value: '' })}>
            Ajouter un contact
          </Button>
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button onClick={handleClose}>Annuler</Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
