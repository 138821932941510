export type OfflineWarning =
  | 'online'
  | 'offline_less_than_a_week'
  | 'offline_more_than_a_week'

export const offlineWarningText: Record<OfflineWarning, string> = {
  online: 'en ligne',
  offline_less_than_a_week: 'hors ligne depuis moins d’une semaine',
  offline_more_than_a_week: 'hors ligne depuis plus d’une semaine',
}

export const offlineWarningColor: Record<OfflineWarning, string> = {
  online: 'bg-green-800',
  offline_less_than_a_week: 'bg-orange-500',
  offline_more_than_a_week: 'bg-red-700',
}
