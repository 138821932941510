import { Trash } from 'lucide-react'
import { get, useFormContext } from 'react-hook-form'
import { Error } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'

export const RoomExitEditor = () => {
  const {
    reset,
    getValues,
    register,
    formState: { isSubmitting, errors },
  } = useFormContext()

  function removeOption() {
    const options = getValues()
    options[`roomExitTimeRange`] = null
    reset(options)
  }

  register('roomExitTimeRange', {
    validate: (roomExitTimeRange) => {
      const start = roomExitTimeRange.start
      const end = roomExitTimeRange.end
      if (start?.length !== end?.length) return 'Heure de début/fin requise'

      return true
    },
  })

  return (
    <>
      <div className="flex flex-wrap items-center gap-3">
        <div className="flex items-center gap-3">
          <label>début</label>
          <Input
            type="time"
            {...register(`roomExitTimeRange.start`, {
              setValueAs: (v) => (v === '' ? null : v),
            })}
          />
        </div>

        <div className="flex items-center gap-3">
          <label>fin</label>
          <Input
            type="time"
            {...register(`roomExitTimeRange.end`, {
              setValueAs: (v) => (v === '' ? null : v),
            })}
          />
        </div>

        <div className="flex items-center gap-3">
          <Button
            onClick={() => removeOption()}
            variant="outline"
            disabled={isSubmitting}
          >
            <Trash />
          </Button>
        </div>
      </div>
      <Error>{get(errors, `roomExitTimeRange.message`)}</Error>
    </>
  )
}
